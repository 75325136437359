ion-item.note_list_item {
  margin: 10px 4px;
  --border-color: #F2F1F2;
  --border-width: 1px;
  --border-radius: 16px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  background: white;
  --background: white;
  border-radius: 16px;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 5px;
}

ion-item.note_list_item:last-of-type {
  margin-bottom: 30px;
}

ion-item.note_list_item::part(native) {
  border-radius: 16px;
  height: 64px;
  --inner-border-width: 0;
}
.note_list_item > div {
  display: flex;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}
.note_list_item h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #171717;
}
.note_list_item  ion-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.note_list_item .age_contents {
  display: flex;
}
.note_list_item svg.feather {
  margin-right: 3px;
}
.note_list_item .age_contents ion-icon {
  width: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

ion-item.note_list_item-profil {
  height: auto;
  --min-height: auto;
  border: 1px solid #F2F1F2;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  --inner-padding-end: 0;
  --padding-start: 0;
}

.note_list_item-profil .wrap-note {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  --min-height: auto;
  position: relative;
  padding: 6px 25px 12px 12px;
}

ion-item.note_list_item-profil::part(native) {
  height: auto;
}

.note_list_item-profil .wrap-note h3 {
  font-weight: 600;
  padding: 5px 0 8px;
}

.wrap-note-details {
  display: flex;
  width: 100%;
  margin-bottom: 3px;
}

.wrap-note-details > div {
  width: 50%;
}

.wrap-note-desc {
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  width: 100%;
}

.note_list_item-profil .notes-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}