.storybook-ionic-button--primary {
    background-color: rgba(226, 237, 237, 1);
  }

.logo {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 12px;
  width: 100%;
  height: 100%;
}

/* .logo--white {
  margin-top: 25px;
} */

.logo--color {
  display: none;
}

.login-password-step {
  position: static;
}

.login-password-step .logo {
  position: static;
}

.login-password-step .logo--color {
  display: block;
}

.login-password-step .logo--white {
  display: none;
}

.login {
  /* --padding-top: 150px; */
  --background: none;
}

.login:after {
  display: none;
}

.banner {
  width: 100%;
}

.login + .login-content {
  --padding-top: 42%;
}

.login-content {
  --padding-top: 32px;
  width: 100%;
  --background: url('/public/assets/images/vagues-2x.png') no-repeat 0 100%;
  background-size: cover;
  --keyboard-offset: 0px !important;
}

.logo__image {
  width: 115px;
  height: 34px;
}

.logo img {
  width: 115px;
  height: 34px;
}

.ionic_header ion-avatar {
  width: 32px;
  height: 32px;
}

.menu-avatar, .menu-avatar ion-avatar {
  height: 32px;
}

.ionic_header ion-avatar.ionic_header_avatar {
  border-radius: 50%;
  --size: auto;
  width: 32px;
  height: 32px;
  background: white;
}

.ionic_header ion-avatar.ionic_header_avatar ion-icon {
  font-size: 20px;
  padding: 5px;
}

ion-header ion-toolbar {
  border-radius: 0px 0px 16px 16px;
}

ion-button[color=light] {
  --background-color: red !important;
}

ion-header ion-title {
  text-align: left;
 /* text-transform: lowercase; */
}

ion-header ion-title span {
  overflow: hidden;
  display: block;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ion-header ion-title span.has-button {
  width: 55vw !important;
}

ion-header ion-title span.title-only {
  width: 75vw !important;
}
ion-header ion-title.with-logo {
  padding-left: 40px !important;
}
ion-header ion-title.without-logo {
  padding-left: 0 !important;
}
ion-header ion-title span:first-letter {
  text-transform: capitalize;
}
ion-header ion-title span {
  text-transform: lowercase;
}
ion-header ion-button[fill=solid][color=light] button {
  --background: white !important;
  background-color: white !important;
}
:host(.button-solid.ion-color) .button-native {
  background-color: white !important;
}

ion-header.ios .without-logo > span{
  padding-left: 40px;
}
.logued-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
