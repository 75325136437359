@import '~leaflet/dist/leaflet.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@font-face {
  font-family: 'Oswald-regular';
  src: url('/public/assets/fonts/Oswald-Regular.eot');
  src: url('/public/assets/fonts/Oswald-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/public/assets/fonts/Oswald-Regular.woff2') format('woff2'),
    url('/public/assets/fonts/Oswald-Regular.woff') format('woff'),
    url('/public/assets/fonts/Oswald-Regular.ttf') format('truetype'),
    url('/public/assets/fonts/Oswald-Regular.svg#Oswald-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('/public/assets/fonts/Inter-Regular.eot');
  src: url('/public/assets/fonts/Inter-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/public/assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('/public/assets/fonts/Inter-Regular.woff') format('woff'),
    url('/public/assets/fonts/Inter-Regular.ttf') format('truetype'),
    url('/public/assets/fonts/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter-regular';
  src: url('/public/assets/fonts/Inter-Regular.eot');
  src: url('/public/assets/fonts/Inter-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/public/assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('/public/assets/fonts/Inter-Regular.woff') format('woff'),
    url('/public/assets/fonts/Inter-Regular.ttf') format('truetype'),
    url('/public/assets/fonts/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-medium';
  src: url('/public/assets/fonts/Inter-Medium.eot');
  src: url('/public/assets/fonts/Inter-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('/public/assets/fonts/Inter-Medium.woff2') format('woff2'),
    url('/public/assets/fonts/Inter-Medium.woff') format('woff'),
    url('/public/assets/fonts/Inter-Medium.ttf') format('truetype'),
    url('/public/assets/fonts/Inter-Medium.svg#Inter-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-bold';
  src: url('/public/assets/fonts/Inter-Bold.eot');
  src: url('/public/assets/fonts/Inter-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/public/assets/fonts/Inter-Bold.woff2') format('woff2'),
    url('/public/assets/fonts/Inter-Bold.woff') format('woff'),
    url('/public/assets/fonts/Inter-Bold.ttf') format('truetype'),
    url('/public/assets/fonts/Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Inter';
}

ion-tabs ion-content,
.ion-page,
.tabs-inner,
body,
.ion-tabs {
  --background: var(--ion-color-main-background);
}
.ion-page.has-map {
  background: transparent !important;
}
.has-map ion-content {
  background: transparent !important;
}

.title {
  font-size: 24px;
  line-height: 33px;
  font-family: 'Inter-bold';
  font-weight: normal;
  color: #374151;
}

.intro {
  font-size: 15px;
  line-height: 20px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #171717;
  margin-bottom: 10px;
}

.ion-text-center ion-text[color='danger'] {
  font-weight: 600;
  font-size: 16px;
}

.ion-touched.ion-invalid {
  color: var(--highlight-color-invalid);
  --color: var(--highlight-color-invalid);
}
.ion-touched.ion-invalid input {
  border-color: #b71c30 !important;
}
/*** Input ***/
ion-list[lines='none'] ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

ion-button {
  text-transform: none;
}

.count .input-label-placement-stacked.sc-ion-input-md-h input.sc-ion-input-md,
.count .input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md,
.count .native-input.sc-ion-input-ios {
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  color: #444141;
  border: none;
  --padding-end: 0;
  --padding-start: 0;
}

.input-label-placement-stacked.sc-ion-input-md-h input.sc-ion-input-md,
.input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md,
.native-input.sc-ion-input-ios {
  padding: 0 10px;
  margin: 0;
  height: 52px;
  border: 1px solid #374151;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  transform: none !important;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #374151;
}

.input-label-placement-stacked.sc-ion-input-md-h input.sc-ion-input-md + input,
.input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md + input,
.native-input.sc-ion-input-ios + input {
  display: none;
}

.view-mode
  .input-label-placement-stacked.sc-ion-input-md-h
  input.sc-ion-input-md,
.view-mode
  .input-label-placement-floating.sc-ion-input-md-h
  input.sc-ion-input-md,
.view-mode
  .input-label-placement-floating.sc-ion-input-ios-h
  input.sc-ion-input-ios,
.view-mode .native-input.sc-ion-input-ios {
  font-size: 16px;
  line-height: 16px;
  color: #374151;
  border: none;
  padding: 0;
  height: 20px;
  text-align: left;
}
.input-bottom.sc-ion-input-ios {
  --border-color: transparent !important;
}
ion-input .input-bottom {
  --padding-top: 0;
}
.error-text {
  font-size: 16px;
  color: rgb(255 166 0) !important;
  font-weight: 600;
}
.error-text:before {
  content: '🅧';
  padding-right: 5px;
  font-weight: normal;
}

.input-label-placement-stacked {
  margin-bottom: 16px;
}

.input-label-placement-stacked
  .input-wrapper
  .label-text-wrapper.sc-ion-input-md {
  max-width: 100%;
  transform: none;
  border: none;
  margin-bottom: 4px;
  color: #374151;
}

.input-label-placement-stacked .input-wrapper .label-text {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter-medium';
  font-weight: normal;
  color: #3c3c3b;
}

.view-mode .input-label-placement-stacked .input-wrapper .label-text {
  font-family: 'Inter-bold';
  font-size: 12px;
  line-height: 20px;
  color: #b0b0b0;
  font-weight: 700;
}

.btn--primary .button-native ion-ripple-effect {
  font-size: 16px;
  line-height: 16px;
  font-family: 'Inter-medium';
  font-weight: normal;
  color: #fff;
  --background: black;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  height: 58px;
}

.header-login {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  --background: none;
}

ion-header {
  --background: none;
  --box-shadow: none;
}

ion-content #background-content {
  --background-border-radius: 20px;
  border-radius: 20px;
  background: blue;
}
/**
* custom style for all segment button
**/
ion-segment[color='primary'] {
  background-color: #dbe6ed;
  border: 1px solid #dbe6ed;
  border-radius: 8px;
  height: 56px;
}
ion-segment-button {
  color: #2f3b6f;
  border-radius: 8px;
  font-size: 14px;
  margin: 0;
  text-transform: none;
}

.segment-button-checked {
  --background: var(--ion-color-primary) !important;
  color: white;
  border: none !important;
}

::part(indicator-background) {
  background: #2f3b6f;
  border-radius: 4px;
}

::part(indicator) {
  height: 48px;
  top: 3px;
  padding-inline: 5px;
}

.segment-button-checked ion-label {
  color: white;
}

.ion-segment-button::part(native) {
  background: red;
}

/* all search bar */
ion-searchbar:not(.in-modal) {
  padding: 0 !important;
  --background: white !important;
  --border-radius: 44px !important;
  --box-shadow: none !important;
}

ion-tabs .has-map ion-content,
.ion-page:has(.has-map),
.tabs-inner:has(.has-map),
.tabs-inner:has(.has-map),
body:has(.has-map),
.ion-tabs:has(.has-map) {
  --background: transparent !important;
}

/** feather icons */
.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.feather.small {
  width: 12px;
  height: 12px;
  display: inline-block;
  stroke: #374151;
}
.feather.medium {
  width: 15px;
  height: 15px;
  display: inline-block;
}
/** custom **/
div.d-flex {
  display: flex;
}
ion-nav {
  position: relative;
  display: block;
  contain: inherit;
}
ion-nav .ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  contain: inherit;
}

h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
}

h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}
h3[color='primary'] {
  color: var(--ion-color-primary);
}

ion-textarea {
  --background: transparent;
}

.main-content ion-col ion-textarea:not(.disabled) {
  padding: 10px;
  opacity: 1;
  border: 1px solid #444141;
  border-radius: 8px;

  overflow-y: auto;
}

.main-content ion-col ion-textarea .textarea-wrapper .native-wrapper textarea {
  padding: 0;
}

.textarea-highlight {
  display: none;
}

.native-textarea.sc-ion-textarea-md {
  transform: none !important;
}

.native-textarea + .native-textarea {
  display: none;
  visibility: hidden;
}

.name,
.age {
  font-size: 12px;
  line-height: 20px;
  color: #374151;
}
ion-col .history-card {
  background: none;
}

.ion-touched.ion-valid.sc-ion-input-md-h .input-bottom.sc-ion-input-md,
.ion-touched.ion-invalid.sc-ion-input-md-h .input-bottom.sc-ion-input-md {
  --border-color: transparent;
}

.icon-home {
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/home.svg') no-repeat 0 0;
}

.tab-selected .icon-home {
  background: url('/public/assets/images/home-color.svg') no-repeat 0 0;
}

.icon-usagers {
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/usager.svg') no-repeat 0 0;
}

.tab-selected .icon-usagers {
  background: url('../../public/assets/images/usager-color.svg') no-repeat 0 0;
}

.icon-actions {
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/flag.svg') no-repeat 0 0;
}

.tab-selected .icon-actions {
  background: url('/public/assets/images/flag-color.svg') no-repeat 0 0;
}

.icon-transmissions {
  width: 24px;
  height: 24px;
  background: url('../../public/assets/images/board.svg') no-repeat 0 0;
}

.tab-selected .icon-transmissions {
  background: url('/public/assets/images/board-color.svg') no-repeat 0 0;
}

.icon-profil {
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/settings.svg') no-repeat 0 0;
}

.tab-selected .icon-profil {
  background: url('/public/assets/images/setting-color.svg') no-repeat 0 0;
}

.text-home {
  font-family: 'Inter-bold';
  font-size: 9px;
  line-height: 20px;
  color: #444141;
}

.tab-selected .text-home {
  color: #e87563;
}

.footer-tabs {
  height: 70px;
}

ion-tab-button {
  background: #fafafa;
  position: relative;
}

ion-tab-button.tab-selected:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 36px;
  height: 4px;
  margin: 0 auto;
  background: #e87563;
  border-radius: 32px;
}

.alert-wrapper.sc-ion-alert-md {
  min-width: 310px;
}

.alert-radio-label.sc-ion-alert-md {
  font-size: 14px;
  padding-inline-start: 40px;
}

.native-textarea.sc-ion-textarea-md,
.legacy-textarea.sc-ion-textarea-md-h
  .textarea-legacy-wrapper.sc-ion-textarea-md::after {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.list-md {
  background: none;
}

.sticky-note {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  /*background: #fafafa;*/
  background: var(--ion-color-main-background);
  z-index: 1;
}

/* ion-checkbox.checkbox-disabled {
  opacity: 1;
} */

.health-title .title-note {
  margin-bottom: 48px;
}

.health-title .update-note {
  color: #444141;
  margin-bottom: 24px;
}

.health-desc p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #374151;
  padding: 0 5px;
}

.health-textarea {
  height: 169px;
}

ion-modal ion-content {
  --offset-top: unset !important;
  --offset-bottom: unset !important;
  overflow: auto !important;
  }