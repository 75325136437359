.list-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-actions .flag-action {
  width: 25px;
  height: 24px;
  background: url('/public/assets/images/flag-action.svg') no-repeat 0 0;
}

.list-actions .usagers-action {
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/usagers-action.svg') no-repeat 0 0;
}

.list-actions .action-home {
  width: 20px;
  height: 24px;
  background: url('/public/assets/images/action-home.svg') no-repeat 0 0;
}

.list-actions .count {
  font-size: 17px;
  line-height: 17px;
  font-weight: 700;
  color: #2F3B6F;
}

.list-actions .subtitle {
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
  color: #2F3B6F;
  margin: 0;
}

.list-actions ion-icon {
  margin-bottom: 8px;
}

.fiche-actions {
  padding-bottom: 12px;
  margin-top: 22px;
  margin-bottom: 16px;
  border-bottom: 1px solid #EBEBEB;
}

.filter-action {
  display: flex;
  padding: 0 10px 10px;
  width: 360px;
  margin: 0 auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll; 
}

.filter-action::-webkit-scrollbar {
  display: none;
}

.btn-filter-action {
  font-size: 12px;
  line-height: 20px;
  height: 32px;
  margin: 0 8px 0 0;
}

.btn-filter-action::part(native) {
  color: #2F3B6F;
  height: 32px;
  background: none;
  border-radius: 41px;
  border: 1px solid #2F3B6F;
  box-shadow: none;
  --padding-start: 10px;
  --padding-end: 10px;
}

.filter-button {
  display: flex;
  align-items: center;
  border-radius: 41px;
  border: 1px solid #2F3B6F;
  margin: 0 8px 0 0;
  padding: 0 10px;
}

.filter-button.has-close {
  background: #E2EDF5;
}

.filter-button .btn-filter-action {
  margin: 0;
}

.filter-button .btn-filter-action::part(native) {
  border: none;
  --padding-start: 0;
  --padding-end: 0;
}

.filter-close {
  display: inline-flex;
  color: transparent;
  text-indent: -9999px;
  margin-left: 9px;
  width: 18px;
  height: 18px;
  background: url(/public/assets/images/close-filter.svg) 0 0 no-repeat;
}

.fixed-actions {
  position: fixed;
  top: 104px;
  left: 0;
  width: 100%;
  background: var(--ion-color-main-background);
  z-index: 3;
}

.liste-actions {
  margin-top: 140px;
}

.multiple-select {
  padding: 0 30px 0 8px;
  height: 52px;
  border: 1px solid #444141;
  border-radius: 8px;
}

.multiple-select::part(icon) {
  width: 10px;
  height: 6px;
  right: -11px;
  color: transparent;
  background: #fff url(/public/assets/images/multiple-puce.svg) 0 0 no-repeat;
}
