.confirm_modal ion-button {
  font-size: 18px;
}

.confirm_modal ion-text {
  font-family: 'Inter-medium';
  font-size: 20px;
  line-height: 22px;
  color: #444141;
  text-align: center;
}
.confirm_modal ion-text p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.confirm_modal h4 {
  font-weight: 600;
}

.confirm_modal ion-button[fill="clear"][color="primary"] {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.cancel, .btn-delete {
  text-decoration: underline;
}

.btn-delete {
  font-family: 'Inter-medium';
  font-size: 16px;
  height: 25px;
  margin: 0;
}

.confirm_modal.modal-qty h4 {
  line-height: 32px;
  margin: 0 0 25px;
}

.confirm_modal.modal-qty .cancel {
  font-weight: 600;
}

.category-selected {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #2F3B6F;
  border-radius: 32px 32px 0px 0px;
  width: 100%;
  height: 290px;
  overflow: hidden;
  z-index: 6;
}

.steptwo {
  height: 165px;
}

.category-selected:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 123px;
  height: 4px;
  background: #F1F1F1;
  border-radius: 16px;
}

.select-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;
}

.select-action ion-text { 
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
}

.list-card .select-action-text,
.select-action .select-action-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.selected-usager {
  position: relative;
}

.selected-usager .delete {
  position: absolute;
  top: 0;
  right: 18px;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.category-selected-usager[data-open="true"] {
  height: calc(100% - 100px);
  overflow-y: auto;
}

.ion-delegate-host {
  border-radius: 24px 24px 0 0;
}

.select-action-modal .ion-delegate-host,
.modal-inconnu .ion-delegate-host {
  background: #2F3B6F;
}

.swipe-modal ion-content::part(background) {
  background: #2F3B6F;
  border-radius: 32px 32px 0px 0px;
}

.swipe-modal::part(handle) {
  top: 8px;
  width: 123px;
  height: 4px;
  background: #F1F1F1;
  border-radius: 16px;
}

.swipe-modal::part(content) {
  border-radius: 32px 32px 0px 0px;
}

.note-modal::part(handle) {
  top: 16px;
  width: 123px;
  height: 4px;
  background: #444141;
  border-radius: 16px;
}

.note-modal::part(content) {
  border-radius: 24px 24px 0px 0px;
}

