.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wraphome-card {
  margin-bottom: 10px;
}

.home-card {
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  width: 50%;
  max-width: 100%;
}

.home-card ion-card-content {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #2F3B6F;
}

.home-card .orange {
  color: #F96D5C;
}

.home-card ion-card-header {
  margin-bottom: 52px;
}

.home-content {
  --padding-top: 10px;
}

.icon-usager {
  width: 54px;
  height: 54px;
  background: url('/public/assets/images/icon-usager.svg') no-repeat 0 0;
}

.icon-flag {
  width: 54px;
  height: 54px;
  background: url('/public/assets/images/icon-flag.svg') no-repeat 0 0;
}

.icon-hand {
  width: 66px;
  height: 90px;
  background: url('/public/assets/images/icon-hand.svg') no-repeat 0 0;
}

.icon-fiche {
  width: 53px;
  height: 80px;
  background: url('/public/assets/images/icon-fiche.svg') no-repeat 0 0;
}

.icon-card {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.picto-flag {
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/picto-flag.svg') no-repeat 0 0;
  margin-right: 10px;
  flex-shrink: 0;
}

.arrow-right {
  width: 32px;
  height: 32px;
  background: url('/public/assets/images/arrow-right.svg') no-repeat 0 0;
  margin-left: auto;
}

.transmission-card {
  background: #FBFBFB;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: url('/public/assets/images/transmission.svg') no-repeat 0 0;
  width: 100%;
  margin-bottom: 22px;
}

.transmission-card  ion-card-content {
  padding-top: 8px;
  padding-bottom: 10px;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}

.transmission-card .transmission--desc p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #171717;
  margin-bottom: 10px;
}

.transmission-card .transmission--user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transmission-card .transmission--user p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #878585;
}

.transmission-card .transmission--user .names { 
  position: relative;
}

.transmission-card .transmission--user .names:before { 
  content: '';
  display: inline-flex;
  top: 50%;
  width: 3px;
  height: 3px;
  background: #878585;
  border-radius: 50%;
  transform: translateY(-50%);
  margin: 0 3px;
}

.system-user {
  width: 27px;
  height: 27px;
  background: url('/public/assets/images/system-user.svg') no-repeat 0 0;
  flex-shrink: 0;
  margin-left: 10px;
}


.title-color {
  color: #374151;
}

.tb-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.wrap-hier {
  margin-bottom: 25px;
}

.btn-hier, .btn-ajd {
  --padding-start: 0;
  --padding-end: 0;
  height: 69px;
  width: 100%;
  border-radius: 16px;
  background: #8DB2A7 url('/public/assets/images/bg-hier.svg') no-repeat 0 0;
}

.btn-ajd { 
  background: #8DB2A7 url('/public/assets/images/bg-ajd.svg') no-repeat 0 0;
  background-size: 100%;
}

.btn-hier::part(native), 
.btn-ajd::part(native) {
  box-shadow: none;
  background: none;
}

.text-hier {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

