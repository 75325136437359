ion-item.action_list_item {
  margin: 10px 4px;
  --border-color: #F2F1F2;
  --border-width: 1px;
  --border-radius: 16px;
  border-radius: 16px;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 4px;
}

ion-item.action_list_item:last-child {
  margin-bottom: 45px;
}

ion-item.action_list_item::part(native) {
  border-radius: 16px;
  height: 64px;
  --inner-border-width: 0;
}

.action_list_item > div {
  display: flex;
  width: 100%;
}

.action_list_item h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #171717;
}
.action_list_item  ion-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.actions--item {
  display: flex;
}

.actions--image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background: #FEEFEE;
  border-radius: 12px;
  margin-right: 10px;
}

.actions--image IonImg {
  width: 24px;
  height: 24px;
}

.actions--counter {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.actions--item .actions--counter ion-label {
  display: flex;
  margin-bottom: 4px;
  white-space: normal;
}

.transmission-item .actions--counter ion-label h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #171717;
  margin: 0 0 4px;
}

.is-new .transmission-item .actions--counter ion-label h3 {
  color: #171717;
}

.transmission-item .actions--counter ion-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color:#878585;
}

.actions--item .action-arrow {
  width: 24px;
  margin-right: 5px;
}

.notes-icon .action-arrow {
  width: 24px;
}

.actions--item .counter {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #4D4D4F;
  width: 15px;
  height: 17px;
  background: rgba(58, 181, 75, 0.1);
  border-radius: 2px;
  padding: 0;
  margin: 0 0 0 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.action-btn {
  margin-top: 20px;
}

.transmission-item {
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  background: #FBFBFB url('/public/assets/images/line-transmission.svg') no-repeat 0 0;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  position: relative;
  background-size: cover;
}

.is-new .transmission-item {
  background:rgba(58, 181, 75, 0.1) url('/public/assets/images/line-transmission-green.svg') no-repeat 0 0;
  background-size: cover;
}

.transmission-user {
  position: absolute;
  right: 0;
  bottom: 0;
}

.chart-vert {
  position: absolute;
  right: 0;
  bottom: 7px;
  width: 8px;
  height: 8px;
  background: #8DB2A7;
  border-radius: 50%;
}

.note-desc {
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #444141;
  margin: 28px 0 16px;
}

.note-date {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #878585;
}

.note-by {
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  color: #878585;
  margin-bottom: 24px;
}

.btn-cancel {
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
}

.note-wrap {
  overflow-y: auto;
}