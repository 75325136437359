ion-item.usager_list_item {
  margin: 10px 4px;
  --border-color: #F2F1F2;
  --border-width: 1px;
  --border-radius: 16px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  --background: white;
  border-radius: 16px;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 5px;
  --inner-border-width: 0;
}

ion-item.usager_list_item.selected::part(native) {
  --background: #fff;
  --border-width: 0px;
  -webkit-box-shadow: inset 0px 0px 0px 2px rgba(100,149,237,1);
  -moz-box-shadow: inset 0px 0px 0px 2px rgba(100,149,237,1);
  box-shadow: inset 0px 0px 0px 2px rgba(100,149,237,1);
}

.modal-inconnu .category-selected-usager-row ion-item.selected::part(native) {
  --background: #fff;
  --border-width: 0px;
  -webkit-box-shadow: inset 0px 0px 0px 2px rgba(100,149,237,1);
  -moz-box-shadow: inset 0px 0px 0px 2px rgba(100,149,237,1);
  box-shadow: inset 0px 0px 0px 2px rgba(100,149,237,1);
}

.category-selected ion-item.usager_list_item { 
  border: 2px solid #8DB2A7;
}

ion-item.usager_list_item::part(native) {
  border-radius: 16px;
}

.usager_list_item > div {
  display: flex;
  width: calc(100% - 10px);
}

.usager_list_item h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171717;
  margin: 0 0 4px;
}

.usager_list_item  ion-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* .usager_list_item  .inner-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

.usager_list_item .age_contents {
  display: flex;
}

.usager_list_item .age_contents >  *{
  flex-shrink: 0;
}

.usager_list_item svg.feather {
  margin-right: 3px;
  flex-shrink: 0;
}
.usager_list_item .age_contents ion-icon {
  width: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

.usager_list_item .age_contents ion-icon.picto-age {
  width: 11px;
  height: 12px;
  background: url('/public/assets/images/picto-age.svg') no-repeat 0 0;
  margin-left: 0;
}

.liste-usagers--left {
  width: 64%;
  padding-right: 10px;
}

.liste-usagers--right {
  width: 36%;
}

.usager-picto {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-inline-end: 12px;
}

.liste-usagers--inner {
  display: flex;
  align-items: center;
}

.liste-usagers--inner.top {
  margin-bottom: 4px;
}