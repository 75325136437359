.profilusager ion-icon {
  margin-right: 6px;
  font-size: 15px;
  color: #2F3B6F;
  margin-top: 0;
  margin-bottom: 0;
}

.profilusager ion-item {
  --min-height: 20px;
  --inner-border-width: 0;
  --background: none;
}

.profilusager .item.sc-ion-label-md-h, 
.profilusager .item .sc-ion-label-md-h,
.profilusager .sc-ion-label-ios-h.sc-ion-label-ios-s.ios {
  font-family: 'Inter-regular';
  font-size: 12px;
  line-height: 16px;
  color: #171717;
}
.profilusager ion-col {
  padding-top: 0;
  padding-bottom: 0;
}
.profilusager ion-label {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fiche-usager .profiles,
.fiche-usager .coordonate,
.fiche-usager .other {
  background: #fff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px 12px;
  margin-bottom: 16px;
}

.edit-usager .profiles,
.edit-usager .coordonate,
.edit-usager .other {
  background: #fff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 5px 5px 20px;
  margin-bottom: 16px;
}

.other {
  margin-bottom: 55px;
}

.profile-title {
  font-size: 18px;
  line-height: 24px;
  font-family: 'Inter-bold';
  font-weight: normal;
  color: #2F3B6F;
  margin: 0;
}

.profiles--image {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.profil-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.tab-profil .create--profils {
  padding: 10px 10px 20px;
}

.create--profils .profiles,
.create--profils .agenda,
.dates, .usagers, .action {
  background: #fff;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 5px 10px 20px;
  margin-bottom: 16px;
}

.dates {
  margin-top: 20px;
}

.create--profils .agenda { 
  margin-bottom: 0;
}

.logout::part(native) {
  background: #fff;
}

.conditions {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #427DA6;
  text-decoration: underline;
}

.text-green {
  color: #8DB2A7;
  line-height: 18px;
}

.agenda .priority-check {
  margin: 0 6px;
}

.item-check {
  --min-height: auto;
}