.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border-radius: 0;
  --ion-color-base: none !important;
}

.header-container ion-buttons {
  position: absolute;
  top: 6px;
  left: 0;
}

.header-title {
  text-align: center;
  margin: 0;
  flex-grow: 1;
  font-size: 20px;
  line-height: 32px;
  font-family: 'Inter-bold';
  font-weight: normal;
}

.header-connected {
  width: 100%;
  height: 128px;
  top: 0;
  margin-bottom: -24px;
  background: url('/public/assets/images/head-bg.svg') no-repeat 0 0;
  background-size: cover;
}

.header-green {
  background: url('/public/assets/images/header-green.svg') no-repeat 0 0;
  background-size: cover;
}

.new-actions {
  background: url('/public/assets/images/bg-header-orange.svg') no-repeat 0 0;
  background-size: cover;
}

ion-header.header-connected ion-toolbar:first-of-type {
  padding-top: 50px;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}