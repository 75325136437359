.wrap-catagory {
  display: flex;
  align-items: center;
}

.wrap-catagory .category-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 0 12px;
  color: #374151;
}