capacitor-google-map {
  display: inline-block;
  z-index: 102;
  width: 100%;
  height: 100%;
  background: transparent;
  margin-top: -5px;
}
.component-wrapper {
  height:  calc(100vh - 180px);;
  width: 100%;
  display: block;
  background: transparent;
}
.component-wrapper .leaflet-container {
  height:  calc(100vh - 180px);;
  width: 100%;
}
.leaflet-popup {
}

.usager-popup .leaflet-popup-content-wrapper,
.usager-popup  .leaflet-popup-tip {
  width: auto;
  padding: 0;
}
.usager-popup .leaflet-popup-content  {
  margin-left: 0;
  margin-right: 0;
}
.usager-popup .leaflet-popup-tip {
  display: none;
}
.marker-active {
  filter: brightness(2) contrast(100%) hue-rotate(117deg) !important;
}
.leaflet-top  .leaflet-control-zoom.leaflet-bar.leaflet-control {
  /*display: none;*/
  margin-top: 80px;
}

.custom-marker-cluster{
  background-image: url('../../../../../../public/assets/images/usager-marker-x2.png');
  background-size: contain;
  height: 50px !important;
  font-weight: 800;
  line-height: 0 !important;
  text-align: center;
  background-repeat: no-repeat;
  width: 45px !important;
}
.custom-marker-cluster span {
  color: #000000;
  width: 120px;
  display: block;
  margin-top: -15px;
  font-size: 20px;
  margin-left: -20px;
  width: max-content;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: transparent !important;
  box-shadow: none !important;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none !important;
}