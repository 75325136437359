.confirm_modal ion-button {
  font-size: 18px;
}

.confirm_modal ion-text {
  font-family: 'Inter-medium';
  font-size: 20px;
  line-height: 22px;
  color: #444141;
  text-align: center;
}
.confirm_modal ion-text p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.confirm_modal h4 {
  font-weight: 600;
}
.confirm_modal ion-button[fill="clear"][color="primary"] {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
}

.cancel, .btn-delete {
  text-decoration: underline;
}

.btn-delete {
  font-family: 'Inter-medium';
  font-size: 16px;
  height: 25px;
  margin: 0;
}

.modal-qty::part(content) {
  border-radius: 24px 24px 0 0;
}

.qty-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 45px;
}

.qty-buttons .add,
.qty-buttons .remove {
  width: 33px;
  height: 33px;
  --border-radius: 50%;
  margin-inline: 0;
  margin-top: 0;
  margin-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.minus {
  width: 33px;
}

.modal-qty .count {
  flex: 0;
  text-align: center;
  margin: 0 15px;
}

.modal-qty::part(handle) {
  display: none;
}

.list-selection {
  background: none;
}

.list-selection-item {
  border-left: 6px solid #DBE6ED;
  border-radius: 4px;
  background: #fff;
  --min-height: 50px;
  padding: 0;
  margin-bottom: 10px;
}

.list-selection .list-selection-desc {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #171717;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.quantity {
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #2F3B6F;
  background: #E2EDF5;
  border-radius: 2px;
  padding: 4px 4px;
  margin-right: 8px;
  flex-shrink: 0;
}

.delete {
  width: 18px;
  height: 18px;
  background: url('/public/assets/images/close.svg') no-repeat 0 0;
}