.main-content {
  overflow: hidden;
  border-radius: 24px 24px 0 0;
  /*top: -24px; */
  z-index: 11;
  --keyboard-offset: 0 !important;
}

.main-content .label-text-wrapper {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #444141;
}

.main-content .view-mode .label-text-wrapper {
  font-size: 12px;
  font-family: 'Inter-bold';
  color: #b0b0b0;
  text-transform: uppercase;
  font-weight: 700;
}

.main-content
  .age.input-label-placement-start.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.age .label-text.sc-ion-input-ios,
.dob-label {
  margin-bottom: 4px;
}

.dob-label {
  display: inline-block;
}

.create__usager,
.edit-usager form {
  padding: 10px 10px 20px;
}

.ou {
  font-size: 10px;
  line-height: 10px;
  font-family: 'Inter-bold';
  font-weight: normal;
  color: #171717;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
}

.create__usager .ou {
  padding-top: 25px;
}

/* .view-mode .ou {
  display: none;
} */

.input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md {
  opacity: 1;
  border-color: #444141;
  transform: none !important;
}

.input-label-placement-floating.sc-ion-input-md-h
  input.sc-ion-input-md
  + input {
  display: none;
}

.input-label-placement-floating.sc-ion-input-ios-h input.sc-ion-input-ios {
  opacity: 1;
  border: 1px solid #444141;
  border-radius: 8px;
  padding: 0 8px;
  height: 52px;
  transform: none !important;
}

.input-label-placement-floating.sc-ion-input-ios-h
  input.sc-ion-input-ios
  + input {
  display: none;
}

form ion-item {
  --inner-border-width: 0;
  --background: none;
  width: 100%;
}

.has-focus.input-label-placement-floating.sc-ion-input-md-h
  input.sc-ion-input-md,
.has-value.input-label-placement-floating.sc-ion-input-md-h
  input.sc-ion-input-md {
  text-align: left;
}

.false
  .transmission-email.has-focus.input-label-placement-floating.sc-ion-input-md-h
  input.sc-ion-input-md,
.false
  .transmission-email.has-value.input-label-placement-floating.sc-ion-input-md-h
  input.sc-ion-input-md {
  background: #d4d4d4;
}

.input-label-placement-floating.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.input-label-placement-floating.sc-ion-input-ios-h
  .label-text-wrapper.sc-ion-input-ios {
  transform: translateY(0%) scale(1);
  margin-bottom: 4px;
}

.input-label-placement-stacked.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.has-focus.input-label-placement-floating.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.has-value.input-label-placement-floating.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md {
  transform: translateY(0%) scale(1);
  margin-bottom: 4px;
  max-width: 100%;
}

.input-label-placement-stacked.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.has-focus.input-label-placement-floating.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.has-value.input-label-placement-floating.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md
  .sc-ion-input-ios-h.sc-ion-input-ios-s.ios.input-label-placement-floating.in-item.has-focus {
  transform: translateY(0%) scale(1);
  margin-bottom: 4px;
}

.input-label-placement-stacked.sc-ion-input-ios-h
  .label-text-wrapper.sc-ion-input-ios,
.has-focus.input-label-placement-floating.sc-ion-input-ios-h
  .label-text-wrapper.sc-ion-input-ios,
.has-value.input-label-placement-floating.sc-ion-input-ios-h
  .label-text-wrapper.sc-ion-input-ios {
  transform: translateY(0%) scale(1);
  margin-bottom: 4px;
}

.input-label-placement-floating.has-focus.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md,
.input-label-placement-stacked.has-focus.sc-ion-input-md-h
  .label-text-wrapper.sc-ion-input-md {
  color: #444141;
}

.legacy-input.sc-ion-input-md-h .native-input[disabled].sc-ion-input-md,
[disabled].sc-ion-input-md-h,
.legacy-input.sc-ion-input-ios-h .native-input[disabled].sc-ion-input-ios,
[disabled].sc-ion-input-ios-h {
  opacity: 1;
}

[disabled='false'].sc-ion-input-md-h.age,
[disabled='false'].sc-ion-input-ios-h.age {
  opacity: 1;
}

[disabled='true'].sc-ion-input-md-h.age,
[disabled='true'].sc-ion-input-ios-h.age {
  opacity: 0.37;
}

.view-mode [disabled].sc-ion-input-md-h.age,
.view-mode [disabled].sc-ion-input-ios-h.age {
  opacity: 1;
}

.age.input-label-placement-start.sc-ion-input-md-h
  .input-wrapper.sc-ion-input-md,
.input-label-placement-start.sc-ion-input-ios-h
  .input-wrapper.sc-ion-input-ios {
  display: flex;
  flex-direction: column;
}

.age .native-wrapper.sc-ion-input-md,
.age .native-input.sc-ion-input-ios {
  height: 52px;
  border: 1px solid#444141;
  border-radius: 8px;
  padding: 0 10px;
}

.view-mode .age .native-wrapper.sc-ion-input-md,
.view-mode .age .native-input.sc-ion-input-ios {
  font-size: 16px;
  line-height: 16px;
  color: #374151;
  height: 20px;
  border: none;
  padding: 0;
}

.view-mode .native-input.sc-ion-input-md {
  color: #374151;
  height: 20px;
}

.input-wrapper.sc-ion-input-ios {
  background: none;
}

ion-row ion-col ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: none;
  --inner-border-width: 0;
}

ion-checkbox {
  font-size: 14px;
  line-height: 18px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #374151;
}

.item .label-check {
  font-size: 14px;
  font-weight: 400;
  color: #374151;
}

.agenda .item .label-check {
  font-size: 12px;
  opacity: 1;
}

.priority-check {
  margin-inline: 0px 6px;
  --size: 17px;
}

.priority-check::part(container) {
  --border-radius: 5px;
  --border-width: 2px;
  --border-style: solid;
  --border-color: #374151;
  --size: 17px;
}

.priority-check.checkbox-checked::part(container) {
  border-color: #878585;
  background: #878585;
}

.priority-check.checkbox-checked::part(mark) {
  stroke-width: 3px;
}

.ion-color-danger {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #c33423;
}

ion-select {
  height: 52px;
  --border-radius: 8px;
  --border-color: #5e5959;
}

ion-select::part(text),
ion-select::part(placeholder),
.legacy-input.sc-ion-input-md-h .native-input.sc-ion-input-md {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #374151;
  --placeholder-opacity: 1;
}

.list-search .legacy-input.sc-ion-input-md-h .native-input.sc-ion-input-md,
.list-search .legacy-input.sc-ion-input-ios-h .native-input.sc-ion-input-ios {
  border: 1px solid #d5d6db;
  border-radius: 16px;
  height: 48px;
  padding: 0 22px 0 40px;
}

.list-search.item-has-focus {
  --show-full-highlight: 0;
}

.list-search .icon-search {
  position: absolute;
  top: 12px;
  left: 15px;
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/search.svg') no-repeat 0 0;
  z-index: 2;
}

.list-search .icon-close {
  color: #e87563;
  position: absolute;
  top: 15px;
  right: 18px;
  font-size: 18px;
  z-index: 2;
}

ion-select::part(icon) {
  position: absolute;
  right: 14px;
}

ion-select .native-wrapper {
  width: 100%;
}

.view-mode ion-select {
  --border-color: none;
}

.select-text,
.select-placeholder {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #c33423;
}

.label-select {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter';
  font-weight: 500;
  color: #444141;
  display: block;
  margin-bottom: 4px;
}

.view-mode .label-select {
  font-size: 12px;
  font-family: 'Inter-bold';
  color: #b0b0b0;
  text-transform: uppercase;
  font-weight: 700;
}

.view-mode .hide {
  display: none;
}

.sc-ion-select-popover-md .select-interface-option {
  padding: 0 10px;
}

ion-list.sc-ion-select-popover-md ion-radio.sc-ion-select-popover-md {
  opacity: 1;
}

.item-radio-checked.sc-ion-select-popover-md {
  --background: none;
}

.view-mode ion-select {
  --padding-start: 0;
  height: 22px;
  min-height: 22px;
}

.view-mode ion-select::part(icon) {
  display: none;
}

.view-mode ion-select::part(text) {
  font-family: 'Inter-medium';
  font-size: 16px;
  line-height: 20px;
  color: #374151;
}

.ion-label {
  font-family: 'Inter-medium';
  font-size: 14px;
  line-height: 20px;
  color: #444141;
}

.research {
  margin-bottom: 8px;
}

.research .counter {
  font-family: 'Inter-regular';
  font-size: 10px;
  line-height: 19px;
  width: 20px;
  height: 19px;
  padding: 0;
}

ion-searchbar:not(.in-modal) .searchbar-input {
  height: 48px !important;
  border: 1px solid #d5d6db !important;
  padding-left: 42px;
  padding-inline-end: 30px;
  border-radius: 16px;
}

.searchbar-search-icon.sc-ion-searchbar-md {
  color: transparent;
  top: 12px;
  left: 15px;
  width: 24px;
  height: 24px;
  background: url('/public/assets/images/search.svg') no-repeat 0 0;
}

.searchbar-input.sc-ion-searchbar-md {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.searchbar-input.sc-ion-searchbar-md:focus::placeholder {
  opacity: 0;
}

ion-searchbar:not(.in-modal) .searchbar-input::placeholder {
  font-family: 'Inter-regular';
  font-size: 14px;
  line-height: 14px;
  color: #d3d3d7;
}

.searchbar-clear-icon.sc-ion-searchbar-md {
  width: 16px;
  color: #e87563;
}

.list-usagers .searchbar-search-icon.sc-ion-searchbar-md {
  top: 13px;
  right: auto !important;
  left: 17px !important;
  opacity: 0.5;
}

.inner-back-button {
  font-family: 'Inter-medium';
  font-size: 12px;
  line-height: 12px;
  color: #878585;
  margin: 0 2px;
  height: 18px;
  margin-bottom: 8px;
}

.profiles .inner-back-button {
  margin-bottom: 0;
}

.inner-back-button ion-icon {
  width: 24px;
  margin-right: 4px;
}

.inner-back-button::part(native) {
  color: #878585;
  padding: 0;
}

.list-card-category .inner-back-button {
  margin-bottom: 12px;
}

.note-title {
  margin-bottom: 15px;
}

.update-health .note-title h3 {
  margin-bottom: 24px;
}

.note-title ion-icon {
  width: 24px;
  margin-right: 8px;
}

.note_list_item {
  height: 64px;
}

.note_list_item > div {
  height: 64px;
}

.textarea-fill-solid.sc-ion-textarea-md-h .textarea-wrapper.sc-ion-textarea-md {
  background: none;
  border: none;
  padding: 0;
}

/* text area */
ion-col ion-textarea:not(.disabled) {
  border: none;
}

.has-value.textarea-label-placement-floating.sc-ion-textarea-ios-h
  .label-text-wrapper.sc-ion-textarea-ioser {
  transform: none;
  max-width: 100%;
}

ion-select.select-disabled {
  opacity: 1;
}

ion-datetime-button {
  background: none;
}

ion-datetime-button::part(native) {
  background: none;
  opacity: 1;
  width: 100%;
}

.has-focus.sc-ion-input-md-h input.sc-ion-input-md {
  color: #374151;
  caret-color: #374151;
}

.sc-ion-input-ios-h {
  --highlight-color: #374151;
  --highlight-color-valid: #374151;
  --highlight-color-focused: #374151;
  --highlight-color-invalid: #374151;
}

.outline-container {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #374151;
  height: 52px;
  border: 1px solid #374151;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
}

.outline-container input::placeholder {
  text-transform: lowercase;
}
