.new-note {
  position: relative;
  margin: 10px 0;
}

.new-note span {
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
  color: #8DB2A7;
  text-transform: uppercase;
  padding: 0 8px;
  background: var(--ion-color-main-background);
  position: relative;
  display: inline-block;
  z-index: 2;
}

.new-note:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #8DB2A7;
  z-index: 1;
}

.is-new {
  margin-bottom: 16px;
  border-bottom: 1px solid #8DB2A7;
}