.details-form {
  padding-top: 182px;
}

.details-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  background: #feefee;
  border-radius: 12px;
  margin-right: 8px;
}

.details-actions-wrap {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 24px;
}

.details-actions-right h3 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}

.details-actions-right .desc {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #374151;
  margin: 0;
}

.create-by {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border: 1px solid #ededed;
  border-radius: 8px;
}

.create-by-image {
  margin-right: 12px;
}

.create-by-desc p {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
}

.create-note .create-by {
  border-color: #F6CA6B;
}

.text-action {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 16px;
}

.btn-assign {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.btn-register--row {
  /* margin-top: 100px; */
  position: sticky;
  bottom: 0;
  left: 0;
  background: var(--ion-color-main-background);
  z-index: 2;
}

.usager-list {
  padding-top: 170px;
}
.filter-orange {
  filter: invert(82%) sepia(13%) saturate(7038%) hue-rotate(309deg)
    brightness(94%) contrast(95%);
}
