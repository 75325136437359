.tabs-row {
  margin-bottom: 8px;
}

/* .tabs-content {
  height: 100%;
  position: relative;
} */

.step-content {
  position: relative;
  z-index: 2;
}

/* .step-content > form {
  padding-top: 92px;
} */

/* .step-content .list-card {
  padding-top: 175px;
} */

/* .step-content .list-card-category {
  padding-top: 110px;
} */

/* .step-content .list-action {
  padding-top: 190px;
} */

.step-content .show {
  padding-bottom: 290px;
}

/* .step-content .validated {
  padding-top: 48px;
} */

.step-buttons {
  position: relative;
  z-index: 2;
  padding-bottom: 30px;
}

.wrap-stepper {
  position: sticky;
  top: 0;
  left: 0;
  padding: 25px 16px 0;
  border-radius: 24px 24px 0 0;
  width: 100%;
  background: #fff;
  z-index: 4;
}

.stepper {
  margin-bottom: 18px;
  background: none;
}

.tabs-content:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 250px;
  background: url('/public/assets/images/vagues-2x.png') no-repeat 0 100%;
  background-size: cover;
  z-index: 1;
}

.step-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 16px;
}

ion-segment[value="3"] + .step-title {
  height: 50px;
  margin-bottom: 10px;
}

.step-number {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #323232;
}

.stepper ion-segment-button {
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  height: 28px;
  line-height: 28px;
  background: #dbe6ed;
  border-radius: 10px;
  --indicator-height: 0;
  --padding-end: 0;
  --padding-start: 0;
  margin: 0 30px;
}

.segment-button-checked .step-number,
.stepper ion-segment-button.active .step-number {
  color: #fff;
}

.stepper ion-segment-button::part(native) {
  border-radius: 10px;
}

.stepper ion-segment-button.segment-button-checked::part(native) {
  background: #8db2a7;
  border-radius: 10px;
}

.stepper ion-segment-button.segment-button-checked.no-active::part(native) {
  background: #dbe6ed;
  border-radius: 10px;
}

.no-active.segment-button-checked .step-number {
  color: #323232;
}

.stepper ion-segment-button:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  display: inline-block;
  margin: auto;
  width: 60px;
  height: 2px;
  background: #dbe6ed;
  opacity: 1;
}

.stepper ion-segment-button.segment-button-checked:before,
.stepper ion-segment-button.active:before {
  background: #8db2a7;
}

.stepper ion-segment-button.segment-button-checked.no-active:before {
  background: #dbe6ed;
}

.stepper ion-segment-button:first-child:before {
  display: none;
}

.stepper ion-segment-button.active {
  background: #8db2a7;
}

.textarea-legacy-wrapper textarea::placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.textarea-signal {
  height: 176px;
}

.textarea-note {
  height: 126px;
}

.textarea-signal-actions {
  height: 81px;
}

.validated ion-img {
  width: 104px;
  height: 104px;
  margin: 0 auto 16px;
  display: block;
}

.validated .title {
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
  color: #171717;
  display: block;
  margin-bottom: 16px;
}

.validated .desc {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #171717;
  display: block;
  margin-bottom: 16px;
}

.btn-usager {
  --border-radius: 16px;
  margin: 0;
}