.segment-button-ios-only {
  border-color: #4a4a4a;
  background-color: #f4f4f4;
  color: #4a4a4a;
  font-weight: normal;
  --indicator-color: #4a4a4a;
}

.segment-button-ios-only.segment-activated {
  background-color: #4a4a4a;
  color: #f4f4f4;
}

.wrap-profil {
  margin: 18px 0 25px;
}

.profil-name {
  font-size: 20px;
  line-height: 28px;
  font-family: 'Inter-bold';
  font-weight: normal;
  color: #3C3C3B;
}

.profil-picto ion-thumbnail {
  width: auto;
  height: auto;
  margin-right: 15px;
}

.ficheusager-tabs {
  background: #E2EDF5;
  border-radius: 64px;
  height: 44px;
  padding: 6px 8px;
  margin-bottom: 14px;
}

.update-health .ficheusager-tabs {
  margin-bottom: 0;
}

.ficheusager-tabs ion-segment-button {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Inter-regular';
  font-weight: normal;
  color: #323232;
  min-width: auto;
  height: 32px;
  min-height: 32px;
  border-radius: 32px;
  --padding-end: 10px;
  --padding-start: 10px;
  --border-width: 0;
}

.ficheusager-tabs .segment-button-checked {
  color: #fff;
  min-width: auto;
  border-radius: 32px;
  --indicator-height: 0;
  background: #2F3B6F;
}
